import classNames from 'classnames'
import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { ImageWithBasePath } from './ImageWithBasePath'
import { SecondaryButton } from '../components/SecondaryButton'

export const FloatingFilters = () => {
  const { setFilterProps, filterProps, theme, floatingFiltersData } = useContext(AppContext)
  return (
    <div className="scroll flex w-[100%] bg-[white] px-[2px]">
      {floatingFiltersData?.map((item: { name: string }, index: number) => (
        <div
          className={classNames(
            'my-[4px] flex w-fit max-w-[33.33%] shrink-0 cursor-pointer select-none items-center whitespace-nowrap py-[6.5px] px-[8px] shadow-secondaryButtonShadow',
            index > 0 && 'ml-[5px]',
            filterProps?.category?.includes(item?.name) && 'border-[1px] transition duration-700'
          )}
          style={{
            borderColor: filterProps?.category?.includes(item?.name) ? theme.customPrimary : '',
            backgroundColor: filterProps?.category?.includes(item?.name) ? theme.custom50 : ''
          }}
          key={index}
          onClick={() => {
            setFilterProps((prev: {category: string[]}) => {
              let previousItems = prev?.category?.length ? { ...prev } : { category: [] }
              let flag = 0
              previousItems?.category?.map((filterProp: string, index: number) => {
                if (filterProp === item?.name) {
                  flag = 1
                  previousItems?.category?.splice(index, 1)
                }
              })
              if (flag === 0) {
                previousItems?.category?.push(item?.name)
              }
              return previousItems
            })
          }}
        >
          <SecondaryButton title={item?.name} icon={item?.name} key={index} />
          {filterProps?.category?.includes(item?.name) && (
            <ImageWithBasePath src="crossIcon" height={17} width={17} className="ml-[4px]" />
          )}
        </div>
      ))}
    </div>
  )
}
