import React from 'react'
import { ChooseMeal } from './ChooseMeal'
import { DatePickerComponent } from './DatePicker'

export const ChooseDateAndMeal = () => {
  return (
    <div>
      <div className="pb-[8px] font-black text-lg font-[500] leading-[21.86px]">
        When are you ordering for?
      </div>
      <div className="flex w-[100%] justify-between">
        <div className="w-[50%] pr-[12px]">
          <DatePickerComponent />
        </div>
        <div className="w-[50%] pl-[12px]">
          <ChooseMeal />
        </div>
      </div>
    </div>
  )
}
