import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { AppContext } from './Layout'
import { BottomShowComponent } from './BottomShowComponent'
import { cartPriceHandler } from '../utils/functions'

export const BottomCart = () => {
  const { cartItems, cartTotalPrice, setCartTotalPrice, tenant, profileData, setIsEditProfileOpen } = useContext(AppContext)

  const router = useRouter()

  useEffect(() => {
    const price = cartPriceHandler(cartItems)
    setCartTotalPrice(String(price))
  }, [cartItems])

  return (
    <>
      {cartItems?.length > 0 && (
        <BottomShowComponent
          value={
            cartItems.length === 1
              ? `${cartItems.length} Item | ${tenant?.currencySymbol} ${cartTotalPrice}`
              : `${cartItems.length} Items | ${tenant?.currencySymbol} ${cartTotalPrice}`
          }
          buttonText="View Cart"
          onClick={async () => {
            if (profileData?.firstName) {
              await router.push({ pathname: '/cart' })
            } else {
              setIsEditProfileOpen(true)
              router.push({
                // @ts-ignore
                pathname: '/profile',
                query: { addUser: true, previousRoute: '/cart' }
              })
            }
          }}
          className="bottom-[71px]"
        />
      )}
    </>
  )
}
