import _JSXStyle from 'styled-jsx/style'
import { CloseIcon } from '../svgIcons/closeIcon'
import classNames from 'classnames'
import { VariantType } from '../ts/types/product.types'
import { ContainsAllergensProps } from '../ts/types/all.types'

export const ContainsAllergens = ({ setIsContainAllergensOpen, items }: ContainsAllergensProps) => {
  return (
    <div
      style={{
        position: 'relative',
        zIndex: 2
      }}
    >
      <div className="flex items-center justify-between pt-[15px] pb-[16px] pl-[16px] pr-[19.75px]">
        <div className="font-black text-2xl font-[500] leading-27 text-[#6ac52b]">
          Allergens in the dish
        </div>
        <div
          className="absolute top-[19.5px] right-[19.5px]"
          onClick={() => setIsContainAllergensOpen(false)}
        >
          <CloseIcon width="14.5" height="14.5" fillColor="#6AC52B" />
        </div>
      </div>

      <div className="flex items-center pb-[24px] pl-[16px] ">
        {items?.allergens?.map((item, index) => (
          <>
            <div
              className={classNames(
                'font-book text-sm font-medium leading-16',
                index !== 0 && 'pl-[4px]'
              )}
            >
              {item}
            </div>
            {index === items?.allergens?.length - 1 ? '' : ','}
          </>
        ))}
      </div>
    </div>
  )
}
