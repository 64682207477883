import _JSXStyle from 'styled-jsx/style'
import { DatePicker, Image } from 'antd'
import { useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import moment from 'moment'
import { AppContext } from './Layout'
import { CalendarIcon } from '../svgIcons/calendarIcon'
import { CrossIcon } from '../svgIcons/crossIcon'
import { DownArrow } from '../svgIcons/downArrow'
import { useDeviceSize } from '../hooks/useDeviceSize'
import leftArrow from '../../public/assets/leftArrow.png'
import rightArrow from '../../public/assets/rightArrow.png'

const DatePickerIcon = ({ color }: { color: string }) => {
  return <CalendarIcon height="12" width="12" fillColor={color} viewBox="0 0 12 12" />
}

export const DatePickerComponent = () => {
  const [isopen, setIsOpen] = useState(false)
  const {
    mealDate,
    setMealDate,
    focusVar,
    setFocusVar,
    theme,
    setDateChangeConformation,
    cartItems,
    setMealType,
    setCheckProduct
  } = useContext(AppContext)
  const [width] = useDeviceSize()

  useEffect(() => {
    if (focusVar) {
      setIsOpen(true)
    }
    setFocusVar(false)
  }, [focusVar])

  const date = new Date()
  const currentDate = moment(date).format('MMMM Do YYYY')

  const dateChangeHandler = (event: any) => {
    setCheckProduct('')
    const d = event.$d.toDateString()
    const splittedDate = d.split(' ')
    const date = `${splittedDate[2]},${splittedDate[1]} '${splittedDate[3].slice(-2)}`
    if (moment(date).format('MMMM Do YYYY') === currentDate) {
      setMealType({
        type: '',
        timeSlot: '',
        startTime: '',
        endTime: '',
        isPickedTimeSlot: false
      })
    }
    setMealDate({
      originalDate: `${splittedDate[2]} ${splittedDate[1]} '${splittedDate[3].slice(-2)}`,
      modifiedDate: date,
      isoString: event.toISOString()
    })
  }

  return (
    <>
      <div className="customDatePicker relative ">
        <div className="relative flex h-[48px] items-center justify-between bg-[white] px-[14px] py-[14.5px]">
          <DatePickerIcon color={theme.customPrimary} />
          <div
            style={{ color: theme.customPrimary }}
            className="mx-[11px] mt-[2px] font-book text-md font-medium leading-[19.12px]"
          >
            {mealDate?.originalDate !== '' &&
            moment(mealDate?.isoString)?.format('YYYY-MM-DD') >=
              moment(new Date())?.format('YYYY-MM-DD')
              ? mealDate?.originalDate
              : 'Choose date'}
          </div>
          {mealDate?.isoString !== '' ? (
            <div
              onClick={() => {
                if (cartItems?.length > 0) {
                  setDateChangeConformation({ isShow: true, text: 'date' })
                } else {
                  setMealDate({ originalDate: '', modifiedDate: '', isoString: '' })
                }
              }}
              className="z-10"
            >
              <CrossIcon
                height="15"
                width="15"
                viewBox="0 0 18 18"
                fillColor={theme.customPrimary}
              />
            </div>
          ) : (
            <DownArrow height="14" width="14" viewBox="0 0 14 14" fillColor={theme.customPrimary} />
          )}
        </div>
        <DatePicker
          className="customDatePicker"
          popupClassName="customDatePickerPopup"
          presets={[
            {
              label: 'Today',
              value: dayjs().add(0, 'd')
            },
            {
              label: 'Tomorrow',
              value: dayjs().add(1, 'd')
            },
            {
              label: 'Day after tomorrow',
              value: dayjs().add(2, 'd')
            }
          ]}
          prevIcon={<Image preview={false} src={leftArrow.src} width="25" height="25" />}
          nextIcon={<Image preview={false} src={rightArrow.src} width="25" height="25" />}
          superNextIcon={''}
          superPrevIcon={''}
          placeholder=""
          format={"DD MMM 'YY"}
          bordered={false}
          suffixIcon=""
          open={isopen}
          onOpenChange={() => {
            if (mealDate?.isoString === '') {
              setIsOpen(!isopen)
            } else {
              if (cartItems?.length > 0) {
                setDateChangeConformation({ isShow: true, text: 'date' })
              } else {
                setIsOpen(!isopen)
              }
            }
          }}
          inputReadOnly={true}
          value={mealDate?.isoString !== '' ? dayjs(mealDate?.isoString) : null}
          showToday={false}
          onSelect={(e: any) => dateChangeHandler(e)}
          onChange={(e: any) => dateChangeHandler(e)}
          allowClear={false}
          disabledDate={(current) => {
            return moment().add(-1, 'days') >= current
          }}
        />
        <style jsx global>{`
          .ant-picker .ant-picker-input,
          .ant-picker.ant-picker-range {
            flex-direction: row-reverse;
            font-family: 'Avenir-book';
            font-size: 14px;
            text-align: center;
            margin: auto;
          }
        `}</style>
        <style jsx global>{`
          .ant-picker .ant-picker-input > input {
            color: transparent;
            position: absolute;
            height: 40px;
          }
          .ant-picker .ant-picker-input span.ant-picker-suffix {
            padding-left: ${width < 768 ? '0%' : '10%'};
            padding-right: 0px;
            margin-right: 5px;
            margin-top: -5px;
          }
          .ant-picker .ant-picker-input > input:placeholder-shown {
            color: ${theme.customPrimary};
          }
          .ant-picker .ant-picker-input input::placeholder {
            color: ${theme.customPrimary};
            font-size: 14px;
            text-align: left;
            font-family: ${width < 760 ? 'Avenir-book' : 'Avenir-black'};
          }
          .ant-picker-dropdown .ant-picker-header > button {
            min-width: 0px;
          }
          .ant-picker-dropdown .ant-picker-header button {
            margin-right: 10px;
            margin-left: 10px;
          }
          .ant-picker-borderless {
            height: 40px;
            position: absolute;
            top: 0px;
            width: 100%;
          }
          .ant-picker-cell-selected .ant-picker-cell-inner {
            background: ${theme.customPrimary} !important;
            color: #232323 !important;
            font-weight: bold;
          }
          .ant-picker-cell-today .ant-picker-cell-inner {
            color: ${theme.customPrimary} !important;
            font-weight: bold;
            color: #232323 !important;
          }
          .ant-picker-presets li {
            display: inline-block !important;
            margin: 0 6px !important;
            padding: 8px !important;
            background: #ffffff;
            cursor: pointer;
            border: 1px solid ${theme.custom50};
          }
          :where(.css-dev-only-do-not-override-sk7ap8).ant-picker-dropdown
            .ant-picker-cell
            .ant-picker-cell-inner {
            border-radius: 100%;
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </div>
    </>
  )
}
