import _JSXStyle from 'styled-jsx/style'
import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from './Layout'
import { ChooseMealIcon } from '../svgIcons/chooseMealIcon'
import { CrossIcon } from '../svgIcons/crossIcon'
import { DownArrow } from '../svgIcons/downArrow'
import { MealDropDown } from './MealDropDown'

export const DesktopMealPicker = () => {
  const { mealType, theme, cartItems, setDateChangeConformation, setMealType } =
    useContext(AppContext)
  const [selectedItem, setSelectedItem] = useState<string | undefined>()

  useEffect(() => {
    setSelectedItem(mealType?.type !== '' ? mealType?.type : 'Choose meal')
  }, [mealType])

  const [showDropDown, setShowDropDown] = useState(false)
  return (
    <div
      className="relative flex h-[48px] w-[100%] items-center justify-center border-solid bg-[#FFFFFF] py-[14.5px]"
      onClick={() => {
        if (mealType?.type !== '' && cartItems?.length > 0) {
          setDateChangeConformation({ isShow: true, text: 'meal' })
        } else {
          setShowDropDown(!showDropDown)
        }
      }}
    >
      <div className="flex items-center">
        <ChooseMealIcon
          height="18"
          width="18"
          fillColor={theme.customPrimary}
          viewBox="0 0 13 13"
        />
        <div
          style={{ color: theme.customPrimary }}
          className="mx-[11px] mt-[2px] font-black text-lg font-medium leading-[28.8px]"
        >
          {selectedItem}
        </div>
      </div>
      {mealType?.type !== '' ? (
        <div
          className="z-30 pt-[1px]"
          onClick={() => {
            if (cartItems?.length > 0) {
              setDateChangeConformation({ isShow: true, text: 'meal' })
            } else {
              setMealType({
                type: '',
                endTime: '',
                startTime: '',
                timeSlot: '',
                isPickedTimeSlot: false
              })
            }
          }}
        >
          <CrossIcon width="20" height="20" viewBox="0 0 20 20" fillColor={theme.customPrimary} />
        </div>
      ) : (
        <div className="pt-[1px]">
          <DownArrow width="20" height="20" viewBox="0 0 16 16" fillColor={theme.customPrimary} />
        </div>
      )}
      {showDropDown && (
        <>
          <div
            className="ovarallPop fixed inset-0 bg-[gray] bg-opacity-40"
            id="bg-inset"
            onClick={() => setShowDropDown(false)}
          ></div>
          <div className="absolute top-[48px] z-20 w-[100%]">
            <MealDropDown setSelectedItem={setSelectedItem} setShowDropDown={setShowDropDown} />
          </div>
        </>
      )}
    </div>
  )
}
