import _JSXStyle from 'styled-jsx/style'
import { Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { AppContext } from './Layout'
import { Media } from '../media'
import { NoOfItemsSelector } from './NoOfItemsSelector'
import { PrimaryButton } from './PrimaryButton'
import { useDeviceSize } from '../hooks/useDeviceSize'
import { CartItemProps } from '../ts/types/cartItem.types'

export const DesktopAskCustomiseAgianModal = () => {
  const {
    theme,
    cartItems,
    customisationItem,
    setOpenCustomisationModal,
    setShowCustomisationScreen,
    setHeaderDetails,
    setopenAskCustomiseAgainModal,
    openAskCustomiseAgainModal,
    setReCustomizeItem,
    setCartItems
  } = useContext(AppContext)
  const [cloneCartItems, setCloneCartItems] = useState([])
  const [width] = useDeviceSize()

  useEffect(() => {
    let array: CartItemProps[] | [] = [...cartItems]
    setCloneCartItems(JSON.parse(JSON.stringify(array)))
  }, [customisationItem])

  return (
    <Media greaterThan="sm">
      <Modal
        open={openAskCustomiseAgainModal}
        onCancel={() => {
          setCartItems(JSON.parse(JSON.stringify(cloneCartItems)))
          setopenAskCustomiseAgainModal(false)
        }}
        footer={null}
        closable={false}
        centered
      >
        <div className="p-[24px]">
          <div
            className="font-black text-2xl font-[500] leading-[27.32px]"
            style={{ color: theme.customPrimary }}
          >
            You have customised this already
          </div>
          {cartItems.length > 0 &&
            cartItems?.map((product: CartItemProps, index: number) => {
              return (
                product?.foodDetails?.title === customisationItem?.foodDetails?.title && (
                  <div className="flex items-center justify-between">
                    <div className="pt-[8px] font-book text-sm font-medium leading-[16.39px]">
                      {product?.customisationString && product?.customisationString.length > 0
                        ? product?.customisationString?.substring(
                            0,
                            product?.customisationString.length - 1
                          )
                        : 'No customisation'}
                    </div>
                    <div className="pt-[16px]">
                      <NoOfItemsSelector
                        value={product?.quantity}
                        imageDetails={{ height: 15, width: 15 }}
                        style={{ height: '25px', width: '25px' }}
                        valueClassName="px-[9px] border-t-[0.74px] border-b-[0.74px] border-t-[#E5E5E5] border-b-[#E5E5E5]"
                        foodDetails={product?.foodDetails}
                        forCart={true}
                        item={product}
                        index={index}
                      />
                    </div>
                  </div>
                )
              )
            })}
          <div className="flex w-[100%] items-center justify-center pt-[28px]">
            <div
              className="w-[100%] py-[7px] text-center font-black text-sm"
              style={{
                color: cartItems?.length > 0 ? theme.customPrimary : '#23232380'
              }}
              onClick={() => {
                if (cartItems?.length > 0) {
                  width > 768 ? setOpenCustomisationModal(true) : setShowCustomisationScreen(true)
                  setHeaderDetails({
                    title: 'Cancel',
                    onClick: JSON.stringify(`() => {
                    setShowCustomisationScreen(false)
                    setOpenCustomisationModal(false)
                  }`)
                  })
                  setopenAskCustomiseAgainModal(false)
                  setReCustomizeItem({})
                  setCartItems(JSON.parse(JSON.stringify(cloneCartItems)))
                }
              }}
            >
              Customise again
            </div>
          </div>
          <div className="mt-[8px] w-[100%]" onClick={() => setopenAskCustomiseAgainModal(false)}>
            <PrimaryButton title="Confirm" className="py-[9px] text-sm" />
          </div>
        </div>
      </Modal>
      <style jsx global>{`
        .ant-modal .ant-modal-content {
          border-radius: 0px;
        }
      `}</style>
    </Media>
  )
}
