import { useEffect, useRef, useState } from 'react'

export const useUserWindowScroll = ({
  thresholdDistance,
  direction = 'vertical'
}: {
  thresholdDistance: number
  direction?: string
}): boolean => {
  const [scroll, setScroll] = useState<{ height: number; width: number }>({ height: 0, width: 0 })
  const dimensions = useRef<'width' | 'height'>(direction == 'horizontal' ? 'width' : 'height')

  const scrollHandler = () => {
    const width = window.scrollX
    const height = window.scrollY

    setScroll({ width: width, height: height })
  }
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    scrollHandler()
  }, [])
  return scroll[dimensions.current] > thresholdDistance ? true : false
}
