import _JSXStyle from 'styled-jsx/style'
import { Drawer, Modal } from 'antd'
import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { Media } from '../media'

export const DateChangeConformation = () => {
  const {
    setDateChangeConformation,
    dateChangeConformation,
    theme,
    setCartItems,
    setMealDate,
    setMealType
  } = useContext(AppContext)
  return (
    <div>
      <Media greaterThan="sm">
        <Modal
          open={dateChangeConformation?.isShow}
          centered
          footer={null}
          onCancel={() =>
            setDateChangeConformation((prev: { isShow: boolean; text: string }) => ({
              ...prev,
              isShow: false
            }))
          }
        >
          <div className="p-[24px]">
            <div className="font-black text-2xl text-[red]">Warning</div>
            <div className="pt-[8px] font-book text-md">
              {`Your cart items will be removed when you change the ${dateChangeConformation?.text}.`}
            </div>
            <div className="font-book text-md">Are you sure you want to continue?</div>
            <div className="flex pt-[16px]">
              <div
                className="w-[50%] cursor-pointer self-center text-center font-black text-md"
                onClick={() =>
                  setDateChangeConformation((prev: { isShow: boolean; text: string }) => ({
                    ...prev,
                    isShow: false
                  }))
                }
              >
                Cancel
              </div>
              <div
                className="w-[50%] py-[9px] text-center font-black text-md"
                style={{
                  background: `linear-gradient(135deg, ${theme.customPrimary} 0%, ${theme.gradient} 100%)`
                }}
                onClick={() => {
                  setCartItems([])
                  if (dateChangeConformation?.text === 'date') {
                    setMealDate({
                      originalDate: '',
                      modifiedDate: '',
                      isoString: ''
                    })
                  } else if (dateChangeConformation?.text === 'meal') {
                    setMealType({
                      type: '',
                      endTime: '',
                      startTime: '',
                      timeSlot: '',
                      isPickedTimeSlot: false
                    })
                  }
                  setDateChangeConformation((prev: { isShow: boolean; text: string }) => ({
                    ...prev,
                    isShow: false
                  }))
                }}
              >
                Ok
              </div>
            </div>
          </div>
        </Modal>
      </Media>
      <Media at="sm">
        <Drawer
          open={dateChangeConformation?.isShow}
          placement="bottom"
          height={200}
          closable={false}
          onClose={() =>
            setDateChangeConformation((prev: { isShow: boolean; text: string }) => ({
              ...prev,
              isShow: false
            }))
          }
        >
          <div className="p-[24px]">
            <div className="font-black text-2xl text-[red]">Warning</div>
            <div className="pt-[8px] font-book text-md">
              {`Your cart items will be removed when you change the ${dateChangeConformation?.text}.`}
            </div>
            <div className="font-book text-md">Are you sure you want to continue?</div>
            <div className="flex pt-[16px]">
              <div
                className="w-[50%] cursor-pointer self-center text-center font-black text-md"
                onClick={() =>
                  setDateChangeConformation((prev: { isShow: boolean; text: string }) => ({
                    ...prev,
                    isShow: false
                  }))
                }
              >
                Cancel
              </div>
              <div
                className="w-[50%] py-[9px] text-center font-black text-md"
                style={{
                  background: `linear-gradient(135deg, ${theme.customPrimary} 0%, ${theme.gradient} 100%)`
                }}
                onClick={() => {
                  setCartItems([])
                  if (dateChangeConformation?.text === 'date') {
                    setMealDate({
                      originalDate: '',
                      modifiedDate: '',
                      isoString: ''
                    })
                  } else if (dateChangeConformation?.text === 'meal') {
                    setMealType({
                      type: '',
                      endTime: '',
                      startTime: '',
                      timeSlot: '',
                      isPickedTimeSlot: false
                    })
                  }
                  setDateChangeConformation((prev: { isShow: boolean; text: string }) => ({
                    ...prev,
                    isShow: false
                  }))
                }}
              >
                Ok
              </div>
            </div>
          </div>
        </Drawer>
      </Media>
      <style jsx global>{`
        .ant-modal .ant-modal-close-x {
          font-size: 15px;
          color: ${theme.customPrimary};
        }
      `}</style>
      <style jsx global>{`
        .ant-modal .ant-modal-close {
          position: absolute;
          top: 24px;
          right: 24px
          width: 0px;
          height: 0px;
        }
      `}</style>
    </div>
  )
}
