import { useContext } from 'react'
import { Modal } from 'antd'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { AppContext } from './Layout'
import { Media } from '../media'

export const LocationAlert = ({ isUserDetailsFilled }: any) => {
  const router = useRouter()
  const { theme } = useContext(AppContext)

  return (
    <Media greaterThan="sm">
      <Modal centered footer={null} open={isUserDetailsFilled} width={454} closable={false}>
        <div className="bg-[white] py-[24px] px-[24px]">
          <div
            className={classNames(
              `flex justify-center font-black text-lg font-[500] leading-22 text-[${theme?.customPrimary}]`
            )}
          >
            Choose Location
          </div>
          <div className="leading-25px mt-[17px] mb-[16px] text-center font-book text-md font-medium">
            Please choose a location to know if this kitchen serves your place
          </div>
          <div className="flex">
            <button
              className="px-[47.5px] py-[16px] font-black text-sm font-[500] leading-16"
              onClick={() => {
                router.push('/')
                router.replace('/', undefined, { shallow: true })
              }}
            >
              Skip and explore
            </button>
            <button
              className={classNames(
                `ml-[24px] bg-[${theme?.customPrimary}] px-[47.5px] py-[16px] font-black text-sm font-[500] leading-16`
              )}
              onClick={() => router.push({ pathname: '/location' })}
            >
              Choose Location
            </button>
          </div>
        </div>
      </Modal>
      <style jsx global>{`
        .ant-modal .ant-modal-title {
          box-shadow: 0px 5px 24px rgba(47, 94, 15, 0.1);
          color: ${theme?.customPrimary};
        }
      `}</style>
      <style jsx global>{`
        .ant-modal-title {
          font-family: Avenir-black;
          font-size: 16px
          font-weight: 800;
          line-height: 22px;
          padding-top: 17px;
          padding-bottom: 17px;
          padding-left: 24px;
        }
      `}</style>
      <style jsx global>{`
        .ant-modal .ant-modal-close-x {
          font-size: 15px;
          color: ${theme?.customPrimary};
        }
      `}</style>
      <style jsx global>{`
        .ant-modal .ant-modal-close {
          position: absolute;
          top: 17.5px;
          righ: 33.5px
          width: 0px;
          height: 0px;
        }
      `}</style>
      <style jsx global>{`
        .ant-modal .ant-modal-content {
          border-radius: 0px;
          padding: 0px;
        }
      `}</style>
    </Media>
  )
}
