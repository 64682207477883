import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const RightArrowIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => (
  <svg
    style={{ fill: fillColor }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_343_10704)">
      <path
        d="M2.70391 8.3875L3.44557 9.125L7.56641 5L3.44141 0.875L2.70391 1.6125L6.09141 5L2.70391 8.3875V8.3875Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_343_10704">
        <rect
          width={width}
          height={height}
          fill="white"
          transform="translate(10 10) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
)
