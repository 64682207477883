import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { FoodCard } from './FoodCard'
import { FoodListDropDown } from './FoodListDropDown'
import { PRODUCT } from '../ts/interfaces/product.interface'
import { FoodListingProps } from '../ts/types/all.types'

export const FoodListing = ({
  forDesktop = false,
  filteredData,
  openFoodNotAvailable,
  setOpenFoodNotAvailable,
  product,
  height = 0
}: FoodListingProps) => {
  const [elePosition, setElePosition] = useState(0)

  useEffect(() => {
    if (product) {
      const element = document.getElementById(String(product))
      const elementPosition = element?.getBoundingClientRect().top ?? 0
      setElePosition(() => {
        if (elePosition === 0) return elementPosition
        return elePosition
      })

      const offsetPosition = height ? elePosition + height - 100 : elementPosition - 200
      element?.scrollIntoView(true)
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }, [product, height])

  return (
    <>
      {Object.keys(filteredData)?.map((product: any, index: number) => (
        <div key={product.id}>
          <FoodListDropDown
            header={product}
            numberOfItems={filteredData?.[product]?.length}
            shadow={false}
            key={product}
          >
            {filteredData?.[product]?.map((item: PRODUCT, index: number) => (
              <div key={item.id} id={item.title?.split(' ').join('')}>
                <div className="px-[24px]">
                  <FoodCard
                    foodDetails={item}
                    {...{ openFoodNotAvailable, setOpenFoodNotAvailable }}
                  />
                </div>
                <div
                  className={classNames(
                    forDesktop
                      ? 'mx-[24px] border-[0px] border-b-[1px] border-solid border-b-[#E5E5E5]'
                      : index !== filteredData?.[product]?.length - 1 &&
                          'border-[0px] border-b-[1px] border-solid border-b-[#E5E5E5]'
                  )}
                ></div>
              </div>
            ))}
          </FoodListDropDown>
          <div className="mt-[8px]"></div>
        </div>
      ))}
      <div className="mb-[200px]"></div>
    </>
  )
}
