import { useContext } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { AppContext } from './Layout'
import { ChatIcon } from '../svgIcons/chatIcon'
import { PhoneIcon } from '../svgIcons/phoneIcon'
import { ChatDesktopProps } from '../ts/types/all.types'

export const ChatDesktop = ({
  displayNumber,
  isChatOpen,
  setDisplayNumber,
  setIsChatOpen
}: ChatDesktopProps) => {
  const { theme, tenant } = useContext(AppContext)

  return (
    <div className="animate-opacity">
      {!displayNumber && (
        <a
          className={classNames(
            `fixed right-[70px] bottom-[190px] font-black text-md font-[500] leading-19 `
          )}
          target="_blank"
          style={{ color: theme.customPrimary }}
        >
          {tenant?.tenantAddresses?.mobileNumber}
        </a>
      )}

      {!isChatOpen && (
        <div className="fixed right-[20px] bottom-[123px] transition duration-150 ease-in-out">
          <div
            className="flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[white]"
            onClick={() => setDisplayNumber((prev) => !prev)}
          >
            <PhoneIcon width="93" height="93" viewBox="0 0 93 93" fillColor={theme.customPrimary} />
          </div>
          <a
            className=" my-[5px] flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[white]"
            href={`https://wa.me/${tenant?.tenantAddresses?.mobileNumber}`}
            target="_blank"
          >
            <Image
              src={require('../../public/assets/whatsappIcon.png')}
              alt=""
              width="24"
              height="24"
            />
          </a>
        </div>
      )}

      <button
        className="fixed right-[12px] bottom-[52px] rounded-full"
        onClick={() => {
          setIsChatOpen((prev) => !prev), setDisplayNumber(true)
        }}
      >
        <ChatIcon
          width="60"
          height="60"
          viewBox="0 0 18 18"
          fillInnerColor="black"
          fillColor={theme.customPrimary}
        />
      </button>
    </div>
  )
}
