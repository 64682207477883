import { gql } from '@apollo/client'

const LIST_PRODUCTS = gql`
  query MyQuery($limit: Int!, $tenant: ID!, $filter: ProductFilterInput) {
    listProducts(limit: $limit, tenant: $tenant, filter: $filter) {
      nextToken
      products {
        id
        title
        description
        isActive
        dishType
        likes
        price
        servingSize
        productSchedules {
          id
          cutoffTime
          scheduleStartTime
          scheduleEndTime
          cookingTime
        }
        cookingTime
        productImages {
          id
          image
          thumbnail
        }
        productVariants {
          id
          name
          description
          variantType
          isRequired
          allowedNumberOfOptions
          variantOptions {
            id
            variantId
            title
            description
            price
          }
        }
        allergens
        category {
          id
          name
        }
      }
    }
  }
`

export default LIST_PRODUCTS
