import { useRouter } from 'next/router'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { bottomTabs } from '../constants/constants'

export const BottomTabNavigator = () => {
  const {
    selectedTab,
    notifications,
    setShowNotification,
    theme,
    loginDetails,
    setNotificationsLength,
    notificationsLength,
    setSelectedTab
  } = useContext(AppContext)
  const router = useRouter()
  const [newNotification, setNewNotification] = useState(false)
  const [notificationsClicked, setNotificationsClicked] = useState(false)

  useEffect(() => {
    if (notificationsLength < notifications?.length && notificationsClicked) {
      setNotificationsLength(notifications?.length)
      setNotificationsClicked(false)
    }
    if (notificationsLength < notifications?.length) {
      setNewNotification(true)
    }
  }, [notifications?.length, notificationsClicked])

  const tabHandler = (tab: string) => {
    setSelectedTab((prev: string[]): string[] => {
      let previousItems = prev ? [...prev] : []
      previousItems?.map((tab1: string, index: number) => {
        if (tab1 === tab) {
          previousItems.splice(index, 1)
        }
      })
      previousItems.push(tab)
      return previousItems
    })
  }

  useEffect(() => {
    if (window) {
      const path = window.location.pathname
      if (path.includes('profile')) {
        tabHandler('Profile')
      } else if (path === '/') {
        tabHandler('Home')
      } else if (path.includes('notifications')) {
        tabHandler('Notifications')
      }
    }
  }, [])

  return (
    <div className="fixed bottom-[0px] z-30 flex w-[100%] justify-evenly bg-[white] py-[11px] shadow-contactCheffShadow">
      {bottomTabs.map((item, index) => (
        <div
          className="flex flex-col items-center"
          onClick={async () => {
            if (item?.title === 'Notifications') {
              setNewNotification(false)
              setNotificationsClicked(true)
              setShowNotification(true)
            } else {
              setShowNotification(false)
            }
            if (item?.title === 'Profile') {
              if (!loginDetails?.isLogin) {
                await router.push({ pathname: '/login' })
              } else {
                await router.push({ pathname: item.path })
              }
            } else {
              await router.push({ pathname: item.path })
            }
          }}
          key={index}
        >
          {React.cloneElement(<item.icon />, {
            height: '24',
            width: '24',
            fillColor:
              selectedTab?.[selectedTab?.length - 1] === item.title ? theme.customPrimary : 'gray',
            newNotification: loginDetails?.isLogin && newNotification
          })}
          <div
            className={classNames(
              'pt-[10px] font-book text-sm font-medium leading-[15px] ',
              selectedTab?.[selectedTab?.length - 1] === item.title
                ? 'text-black-100'
                : 'text-black-50'
            )}
          >
            {item.title}
          </div>
        </div>
      ))}
    </div>
  )
}
