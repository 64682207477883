import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { productTypeFilterHandler } from '../utils/functions'
import { SearchIcon } from '../svgIcons/searchIcon'

export const SeachBar = () => {
  const {
    setFilteredProducts,
    listProducts,
    theme,
    setPreviouslyOrderedFilterItems,
    previouslyOrderedItems
  } = useContext(AppContext)

  const productTypeFilterHandlerFun = (productsData: any) => {
    const filterdObject = productTypeFilterHandler(productsData)
    setFilteredProducts(filterdObject)
  }

  const searchHandler = (text: string) => {
    let array = listProducts?.filter((contact: any) => {
      let name = contact?.title?.toLowerCase()
      return name.includes(text.toLowerCase())
    })
    let array2 = previouslyOrderedItems?.filter((item: any) => {
      let name = item?.title?.toLowerCase()
      return name.includes(text.toLowerCase())
    })
    setPreviouslyOrderedFilterItems(array2)
    productTypeFilterHandlerFun(array)
    if (text.length === 0) {
      setPreviouslyOrderedFilterItems(previouslyOrderedItems)
      productTypeFilterHandlerFun(listProducts)
    }
  }

  return (
    <div className="flex items-center border-[1px] border-[#E5E5E5] bg-[#FFFFFF] py-[6px] px-[11px]">
      <SearchIcon height="20" width="20" viewBox="0 0 20 20" fillColor={theme.customPrimary} />
      <input
        placeholder="Search for dishes"
        style={{ outline: 'none' }}
        className="ml-[10px] w-full font-book text-md font-medium leading-[25.2px]"
        onChange={(text: any) => searchHandler(text?.target?.value)}
      />
    </div>
  )
}
