import classNames from 'classnames'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { getOperationalHours } from '../utils/functions'
import { mealData } from '../constants/constants'
import { Media } from '../media'
import { MealType } from '../ts/types/product.types'
import { MealDropDownProps } from '../ts/types/all.types'

export const MealDropDown = ({ setSelectedItem, setShowDropDown }: MealDropDownProps) => {
  const { setMealType, mealDate, setFocusVar, tenant, timeZone, tenantOperationalHours, setCheckProduct } =
    useContext(AppContext)
  const choosenDate = getOperationalHours(mealDate)
  const [menuSlots, setMenuSlots] = useState([])
  let currentTime = moment(moment().format())
    .utcOffset(timeZone)
    ?.format('MMMM DD YYYY, HH:MM:ss a')

  const clickHandler = (item: { name: string; startTime: string; endTime: string }) => {
    setCheckProduct('')
    setSelectedItem(item.name)
    setShowDropDown(false)
    setMealType((prev): MealType => {
      let previousItems = { ...prev }
      previousItems.type = item.name
      previousItems.startTime = item?.startTime
      previousItems.endTime = item?.endTime
      previousItems.timeSlot = `${moment(item?.startTime, 'hh:mm A')?.format(
        'hh:mm A'
      )} to ${moment(item?.endTime, 'hh:mm A')?.format('hh:mm A')}`
      previousItems.isPickedTimeSlot = false
      return previousItems
    })
    if (mealDate?.isoString !== '') setFocusVar(false)
  }

  const constants = ['Breakfast', 'Lunch', 'Dinner']

  const dateComparisionHandler = (item: { name: string; startTime: string; endTime: string }) => {
    let condition = `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
      item?.endTime,
      'hh:mm A'
    )?.format('HH:MM:ss a')}`

    let condition1: boolean =
      `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
        tenantOperationalHours?.[choosenDate]?.openTime,
        'hh:mm A'
      )?.format('HH:MM:ss a')}` <=
        `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
          item?.startTime,
          'hh:mm A'
        )?.format('HH:MM:ss a')}` &&
      `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
        item?.endTime,
        'hh:mm A'
      )?.format('HH:MM:ss a')}` <=
        `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
          tenantOperationalHours?.[choosenDate]?.closeTime,
          'hh:mm A'
        )?.format('HH:MM:ss a')}`
    if (condition > currentTime && condition1) {
      clickHandler(item)
    }
  }

  const conditionHandler = (item: { name: string; startTime: string; endTime: string }) => {
    const condition: boolean =
      `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
        tenantOperationalHours?.[choosenDate]?.openTime,
        'hh:mm A'
      )?.format('HH:MM:ss a')}` <=
        `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
          item?.startTime,
          'hh:mm A'
        )?.format('HH:MM:ss a')}` &&
      `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
        item?.endTime,
        'hh:mm A'
      )?.format('HH:MM:ss a')}` <=
        `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
          tenantOperationalHours?.[choosenDate]?.closeTime,
          'hh:mm A'
        )?.format('HH:MM:ss a')}`

    return (
      `${moment(mealDate?.isoString).format('MMMM DD YYYY,')} ${moment(
        item?.endTime,
        'hh:mm A'
      )?.format('HH:MM:ss a')}` > currentTime && condition
    )
  }

  useEffect(() => {
    let filteredData: any = []
    constants.map((item) => {
      const data = tenant?.menuSlots?.find((dishType: { name: string }) => dishType?.name === item)
      if (data) {
        filteredData.push(data)
      }
    })
    setMenuSlots(filteredData)
  }, [])

  return (
    <>
      <Media at="sm">
        <div
          className="animate-dropDownAnim rounded-[4px] bg-[white]"
          style={{ position: 'relative', zIndex: 2 }}
        >
          {menuSlots?.map((item: { name: string; startTime: string; endTime: string }) => (
            <div
              className={classNames(
                'border-b-[1px] border-b-[#E5E5E5] bg-[white] py-[8px] pl-[17px]'
              )}
              onClick={() => dateComparisionHandler(item)}
            >
              <div
                className={classNames(
                  'font-black text-md font-medium leading-[19.12px]',
                  conditionHandler(item) ? 'text-black-100' : 'text-black-20'
                )}
              >
                {item?.name}
              </div>
              <div
                className={classNames(
                  'pt-[4px] font-book text-md font-medium leading-[19.12px]',
                  conditionHandler(item) ? 'text-black-50' : 'text-black-20'
                )}
              >
                {`${moment(item?.startTime, 'hh:mm A')?.format('hh:mm A')} to ${moment(
                  item?.endTime,
                  'hh:mm A'
                )?.format('hh:mm A')}`}
              </div>
            </div>
          ))}
        </div>
      </Media>
      <Media greaterThan="sm">
        <div
          className="animate-dropDownAnim bg-[white] shadow-primaryButtonShadow"
          style={{ position: 'relative', zIndex: 2 }}
        >
          {menuSlots?.map(
            (item: { name: string; startTime: string; endTime: string }, index: number) => (
              <div
                className={classNames(
                  'flex w-[100%] items-center justify-between border-b-[#E5E5E5] px-[17px]',
                  index !== mealData.length - 1 && 'border-b-[1px]'
                )}
                onClick={() => dateComparisionHandler(item)}
              >
                <div
                  className={classNames(
                    'flex-1 py-[12px] font-black text-md font-[500] leading-[19.12px]',
                    conditionHandler(item) ? 'text-black-100' : 'text-black-20'
                  )}
                >
                  {item?.name}
                </div>
                <div
                  className={classNames(
                    'font-black text-md font-medium leading-[19.12px]',
                    conditionHandler(item) ? 'text-black-100' : 'text-black-20'
                  )}
                >
                  {`${moment(item?.startTime, 'hh:mm A')?.format('hh:mm A')} to ${moment(
                    item?.endTime,
                    'hh:mm A'
                  )?.format('hh:mm A')}`}
                </div>
              </div>
            )
          )}
        </div>
      </Media>
    </>
  )
}
