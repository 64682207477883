import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const SearchIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7145 13.2145L18 17.5M9.13633 2.5C7.92268 2.5 6.73628 2.85989 5.72717 3.53416C4.71806 4.20843 3.93155 5.16679 3.4671 6.28806C3.00266 7.40932 2.88114 8.64314 3.11791 9.83347C3.35468 11.0238 3.93911 12.1172 4.79729 12.9754C5.65547 13.8335 6.74886 14.418 7.93919 14.6547C9.12952 14.8915 10.3633 14.77 11.4846 14.3056C12.6059 13.8411 13.5642 13.0546 14.2385 12.0455C14.9128 11.0364 15.2727 9.84998 15.2727 8.63633C15.2726 7.0089 14.626 5.44817 13.4753 4.2974C12.3245 3.14664 10.7638 2.5001 9.13633 2.5Z"
        stroke={fillColor}
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}
