import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const LikeButton = ({ fillColor, width, height, viewBox }: SvgIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00006 9.6L4.89047 9.96528C4.18089 12.3306 4.21916 14.8573 5.00006 17.2M8.68392 5.0257L8.86728 3.13065C8.94104 2.36895 9.65168 1.85228 10.3702 2.03794C11.4514 2.3173 12.21 3.32131 12.21 4.47313V6.901C12.21 7.441 12.21 7.711 12.3272 7.90952C12.3935 8.02184 12.4849 8.11619 12.5935 8.18474C12.7856 8.30592 13.0469 8.30592 13.5694 8.30592H13.8864C15.2486 8.30592 15.9298 8.30592 16.3491 8.61832C16.6629 8.85192 16.8842 9.19496 16.9722 9.58408C17.0899 10.1043 16.8279 10.7541 16.304 12.0537L16.0428 12.7015C15.8919 13.0758 15.8302 13.4818 15.8627 13.8858C16.0481 16.1845 14.2246 18.1219 11.9961 17.994L3.65654 17.5152C2.74582 17.4629 2.29046 17.4367 1.87885 17.0726C1.46725 16.7085 1.38981 16.3335 1.23492 15.5838C1.1029 14.9446 1 14.161 1 13.271C1 12.284 1.12657 11.4278 1.27906 10.7544C1.50526 9.7556 2.45936 9.21856 3.44427 9.34576C6.05509 9.68304 8.42216 7.73144 8.68392 5.0257Z"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
