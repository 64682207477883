import _JSXStyle from 'styled-jsx/style'
import { Collapse } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { ImageWithBasePath } from './ImageWithBasePath'
import { useDeviceSize } from '../hooks/useDeviceSize'
import { FoodListDropDownProps } from '../ts/types/all.types'

export const FoodListDropDown = ({
  children,
  key = '0',
  header = 'Pass the Header',
  numberOfItems = 0,
  shadow = true,
  id
}: FoodListDropDownProps) => {
  const { Panel } = Collapse
  const [width] = useDeviceSize()

  return (
    <Collapse
      bordered={false}
      id={id}
      key={key}
      expandIconPosition="end"
      className={classNames('rounded-none bg-[white]', shadow && 'shadow-foodListShadow')}
      expandIcon={({ isActive }) =>
        width > 768 ? (
          ''
        ) : (
          <ImageWithBasePath
            src="dropDownIcon"
            height={48}
            width={48}
            className={classNames(isActive ? '' : 'rotate-180', 'mr-[24px] mt-[25px]')}
          />
        )
      }
      defaultActiveKey={['0']}
      // @ts-ignore
      collapsible={width > 768 && 'icon'}
    >
      <Panel
        header={
          <div
            className={classNames(
              'h-[48px] px-[24px] pt-[15px] font-black font-[500] leading-[19.12px]',
              width > 768 ? 'text-xl' : 'text-md'
            )}
            id={header}
          >
            {header}
            {numberOfItems > 0 && <span className="ml-[4px]">{`(${numberOfItems})`}</span>}
          </div>
        }
        key={key}
      >
        {children}
      </Panel>
      <style jsx global>{`
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          padding: 0px;
        }
      `}</style>
      <style jsx global>{`
        .ant-collapse-borderless
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          padding: 0px;
        }
      `}</style>
    </Collapse>
  )
}
