import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const ChooseMealIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={{ fill: fillColor }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_32_948)">
        <path
          d="M9 8.25H4.32094L3.36 9.07031L2.39906 8.25H0.375V8.8125C0.375 9.52945 0.870234 10.1135 1.48266 10.125C1.53961 10.4794 1.67742 10.7995 1.88484 11.0501C2.19164 11.4206 2.63766 11.625 3.14063 11.625H6.23438C6.73734 11.625 7.18336 11.4206 7.49016 11.0496C7.69758 10.7991 7.83539 10.4789 7.89234 10.1245C8.21766 10.1187 8.50992 9.95109 8.71266 9.68789C8.902 9.43558 9.00299 9.12794 9 8.8125V8.25Z"
          fill={fillColor}
        />
        <path
          d="M2.46094 7.5L3.3593 8.1607L4.26562 7.5H9V7.3125C8.99974 7.09646 8.925 6.88712 8.78838 6.71976C8.65176 6.5524 8.46161 6.43726 8.25 6.39375C8.23078 5.69789 7.94531 5.11547 7.41773 4.70625C6.92977 4.32563 6.27773 4.125 5.53125 4.125H3.84375C2.24484 4.125 1.1618 5.03367 1.125 6.39375C0.913386 6.43726 0.723243 6.5524 0.586624 6.71976C0.450005 6.88712 0.375264 7.09646 0.375 7.3125V7.5H2.46094Z"
          fill={fillColor}
        />
        <path
          d="M10.8534 2.25H9.10523L9.3143 1.41422L10.3594 1.05469L10.125 0.375L8.67188 0.84375L8.33227 2.25H4.875V3H5.31445L5.35805 3.375H5.53125C6.44531 3.375 7.25766 3.63047 7.87781 4.11375C8.22275 4.37953 8.50237 4.72064 8.69531 5.11102C8.81421 5.3532 8.89885 5.61075 8.9468 5.87625C9.29604 6.09128 9.55502 6.42614 9.67533 6.81824C9.79563 7.21033 9.76902 7.63282 9.60047 8.00672C9.69922 8.26389 9.74991 8.53702 9.75 8.8125C9.75 9.33609 9.57188 9.83508 9.24844 10.2176C9.04828 10.456 8.79189 10.6409 8.50242 10.7555C8.40377 11.0366 8.2568 11.2983 8.06812 11.5289C8.04047 11.5622 8.01211 11.5948 7.98305 11.6262H9.75C10.125 11.6262 10.2891 11.4387 10.3359 11.0871L11.1891 3.00117H11.625V2.25H10.8534Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_32_948">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
