import _JSXStyle from 'styled-jsx/style'
import { DatePicker, Image } from 'antd'
import { useContext } from 'react'
import dayjs from 'dayjs'
import moment from 'moment'

import { AppContext } from './Layout'
import { CalendarIcon } from '../svgIcons/calendarIcon'
import { CrossIcon } from '../svgIcons/crossIcon'
import { DownArrow } from '../svgIcons/downArrow'
import { useDeviceSize } from '../hooks/useDeviceSize'
import leftArrow from '../../public/assets/leftArrow.png'
import rightArrow from '../../public/assets/rightArrow.png'
import { DesktopDatePickerProps } from '../ts/types/all.types'

const DatePickerIcon = ({ color }: { color: string }) => {
  return (
    <CalendarIcon height="17" width="17" fillColor={color} viewBox="0 0 12 12" opacity={false} />
  )
}

export const DesktopDatePicker = ({ isOpen, setIsOpen }: DesktopDatePickerProps) => {
  const {
    mealDate,
    setMealDate,
    theme,
    setDateChangeConformation,
    cartItems,
    setMealType,
    timeZone,
    setCheckProduct
  } = useContext(AppContext)
  const [width] = useDeviceSize()

  const date = new Date()
  const currentDate = moment(date).format('MMMM Do YYYY')

  const dateChangeHandler = (event: any) => {
    setCheckProduct('')
    const d = event.$d.toDateString()
    const splittedDate = d.split(' ')
    const date = `${splittedDate[2]},${splittedDate[1]} '${splittedDate[3].slice(-2)}`
    if (moment(date).format('MMMM Do YYYY') === currentDate) {
      setMealType({
        type: '',
        timeSlot: '',
        startTime: '',
        endTime: '',
        isPickedTimeSlot: false
      })
    }
    setMealDate({
      originalDate: `${splittedDate[2]} ${splittedDate[1]} '${splittedDate[3].slice(-2)}`,
      modifiedDate: date,
      isoString: event.toISOString()
    })
  }

  return (
    <div className="relative">
      <div className="flex items-center justify-center bg-[white] py-[10px]">
        <DatePickerIcon color={theme.customPrimary} />
        <div
          className="px-[10px] font-black text-lg leading-[28.8px]"
          style={{ color: theme.customPrimary }}
        >
          {mealDate?.originalDate !== '' &&
          moment(mealDate?.isoString)?.format('YYYY-MM-DD') >=
            moment(new Date())?.format('YYYY-MM-DD')
            ? mealDate?.originalDate
            : 'Choose date'}
        </div>
        {mealDate?.isoString !== '' ? (
          <div
            className="z-30 pt-[1px]"
            onClick={() => {
              if (cartItems?.length > 0) {
                setDateChangeConformation({ isShow: true, text: 'date' })
              } else {
                setMealDate({
                  originalDate: '',
                  modifiedDate: '',
                  isoString: ''
                })
              }
            }}
          >
            <CrossIcon width="20" height="20" viewBox="0 0 20 20" fillColor={theme.customPrimary} />
          </div>
        ) : (
          <div className="pt-[1px]">
            <DownArrow width="20" height="20" viewBox="0 0 16 16" fillColor={theme.customPrimary} />
          </div>
        )}
      </div>
      <DatePicker
        className="customDatePicker flex font-black"
        popupClassName="customDatePickerPopup"
        presets={[
          {
            label: 'Today',
            value: dayjs().add(0, 'd')
          },
          {
            label: 'Tomorrow',
            value: dayjs().add(1, 'd')
          },
          {
            label: 'Day after tomorrow',
            value: dayjs().add(2, 'd')
          }
        ]}
        prevIcon={<Image preview={false} src={leftArrow.src} width="25" height="25" />}
        nextIcon={<Image preview={false} src={rightArrow.src} width="25" height="25" />}
        superNextIcon={''}
        superPrevIcon={''}
        placeholder=""
        format={"DD MMM 'YY"}
        bordered={false}
        suffixIcon=""
        // @ts-ignore
        open={isOpen}
        onOpenChange={() => {
          if (mealDate?.isoString === '') {
            setIsOpen(!isOpen)
          } else {
            if (cartItems?.length > 0) {
              setDateChangeConformation({ isShow: true, text: 'date' })
            } else {
              setIsOpen(!isOpen)
            }
          }
        }}
        inputReadOnly={true}
        showToday={false}
        value={mealDate?.isoString !== '' ? dayjs(mealDate?.isoString) : null}
        onChange={(e) => dateChangeHandler(e)}
        onSelect={(e) => dateChangeHandler(e)}
        allowClear={false}
        disabledDate={(current) => {
          return moment().add(-1, 'days') >= current
        }}
      />
      <style jsx global>{`
        .ant-picker .ant-picker-input,
        .ant-picker.ant-picker-range {
          flex-direction: row-reverse;
          font-family: 'Avenir-black';
          text-align: center;
          margin: auto;
        }
      `}</style>
      <style jsx global>{`
        .ant-picker .ant-picker-input > input {
          color: transparent;
          position: absolute;
          height: 50px;
          margin-top: -45px;
        }
        .ant-picker .ant-picker-input span.ant-picker-suffix {
          margin-left: ${width > 768 ? '20%' : '5%'};
          margin-top: -5px;
        }
        .ant-picker .ant-picker-input > input:placeholder-shown {
          color: ${theme.customPrimary};
          font-family: 'Avenir-black';
          font-weight: 800;
        }
        .ant-picker .ant-picker-input input::placeholder {
          color: ${theme.customPrimary};
          font-size: 16px;
          font-weight: 800;
          font-family: 'Avenir-black';
          text-align: left;
        }
        .ant-picker-cell-selected .ant-picker-cell-inner {
          background: ${theme.customPrimary} !important;
          color: #232323 !important;
          font-weight: bold;
          border-radius: 100%;
        }
        .ant-picker-cell-today .ant-picker-cell-inner {
          color: ${theme.customPrimary} !important;
          font-weight: bold;
          color: #232323 !important;
        }
        .ant-picker-presets li {
          display: inline-block !important;
          margin: 0 6px !important;
          padding: 8px !important;
          background: #ffffff;
          cursor: pointer;
          border: 1px solid ${theme.custom50};
        }
        :where(.css-dev-only-do-not-override-sk7ap8).ant-picker-dropdown
          .ant-picker-cell
          .ant-picker-cell-inner {
          border-radius: 100%;
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  )
}
