import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const PhoneIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_317_4730)">
      <path
        d="M55.3693 50.6953V54.3039C55.3695 54.5623 55.2716 54.8111 55.0954 55.0001C54.9193 55.1891 54.678 55.3043 54.4203 55.3224C53.9743 55.353 53.61 55.3693 53.3283 55.3693C44.31 55.3693 37 48.0593 37 39.041C37 38.7593 37.0153 38.395 37.0469 37.9491C37.065 37.6913 37.1802 37.45 37.3692 37.2739C37.5583 37.0977 37.807 36.9999 38.0654 37H41.674C41.8006 36.9998 41.9227 37.0468 42.0166 37.1316C42.1105 37.2165 42.1695 37.3333 42.1822 37.4592C42.2057 37.6939 42.2271 37.8807 42.2475 38.0225C42.4503 39.4379 42.8659 40.8145 43.4803 42.1056C43.5772 42.3097 43.514 42.5536 43.3303 42.6843L41.128 44.2579C42.4745 47.3954 44.9749 49.8958 48.1124 51.2423L49.684 49.0441C49.7483 48.9543 49.842 48.8899 49.9489 48.8621C50.0557 48.8343 50.1689 48.8449 50.2688 48.8921C51.5598 49.5053 52.936 49.9199 54.3509 50.1218C54.4927 50.1422 54.6795 50.1647 54.9121 50.1871C55.0379 50.2 55.1544 50.2591 55.2391 50.353C55.3237 50.4469 55.3705 50.5689 55.3704 50.6953H55.3693Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_317_4730"
        x="0"
        y="0"
        width="93"
        height="93"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.184314 0 0 0 0 0.368627 0 0 0 0 0.0588235 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_4730" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_317_4730"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
