import { ImageWithBasePath } from './ImageWithBasePath'

export const CartEmpty = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center font-black text-[24px] font-[500] leading-22 tracking-[-0.41px]">
        Your cart is empty
      </div>
      <div className="pt-[36px]">
        <ImageWithBasePath src="emptyCartIcon" height={104.55} width={165} alt="" />
      </div>
    </div>
  )
}
