import _JSXStyle from 'styled-jsx/style'
import { Popover } from 'antd'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useContext, useState } from 'react'
import { AppContext } from './Layout'
import { ImageWithBasePath } from './ImageWithBasePath'
import { Media } from '../media'

export const NotificationDesktop = () => {
  const { showNotification, setShowNotification, notifications, setOrderId, setOrderDetails } =
    useContext(AppContext)
  const router = useRouter()
  const [orders, setOrders] = useState<any>({})

  useEffect(() => {
    if (notifications.length > 0) {
      const arr = notifications?.slice().sort(function (a: any, b: any): any {
        return +new Date(b?.createdAt) - +new Date(a?.createdAt)
      })

      let currentDate = moment(new Date()).format('DD-MM-YYYY')
      let yesterdayDate = moment().subtract(1, 'day').format('DD-MM-YYYY')

      const groups = arr.reduce((groups: { [x: string]: any[] }, order: { createdAt: string }) => {
        let date = moment(order.createdAt.split('T')[0]).format('DD-MM-YYYY')
        if (date === currentDate) date = 'Today'
        else if (moment(date).subtract(1, 'day').format('DD-MM-YYYY') === yesterdayDate)
          date = 'Yesterday'
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(order)
        return groups
      }, {})
      setOrders(groups)
    }
  }, [notifications])

  const handleOpenChange = (newOpen: boolean) => {
    setShowNotification(newOpen)
  }

  const handleScroll = () => {
    setShowNotification(false)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const content = (
    <div className="scroll fixed top-[55px] right-[165px] h-[560px] overflow-y-scroll bg-[white] shadow-primaryButtonShadow">
      {Object.keys(orders)?.length ? (
        Object.keys(orders).map((item: string, index) => {
          return (
            <div>
              <div
                style={{ minWidth: 300 }}
                className={classNames(
                  ' border-b border-black-20 bg-[#F5F5F6] py-[8px] pl-[24px] font-black text-md font-[500] leading-19 text-black-75',
                  index !== 0 && 'border-t'
                )}
              >
                {item}
              </div>
              {orders?.[item]?.map((eachItem: any) => (
                <>
                  <div
                    className="py-[12px] pr-[17px] pl-[18px]"
                    onClick={async () => {
                      setOrderId(eachItem?.order?.orderId)
                      setOrderDetails({})
                      await router.push({
                        pathname: '/order',
                        query: {
                          order: eachItem?.order?.orderId,
                          review: eachItem?.order?.review
                        }
                      })
                    }}
                  >
                    <div className="mb-[4px] flex items-center justify-between">
                      <div className="font-black text-md font-[500] leading-19 text-green-100">
                        <div style={{ cursor: 'pointer' }}>{eachItem?.title}</div>
                      </div>
                      <div className="font-md ml-[10px] font-book font-medium leading-19 text-black-50">
                        {moment(eachItem?.createdAt).format('hh:mm A')}
                      </div>
                    </div>
                    <div>{eachItem?.description}</div>
                  </div>
                  <div className="w-full border-b text-[#E5E5E5]"></div>
                </>
              ))}
            </div>
          )
        })
      ) : (
        <div className="flex h-[70vh] flex-col items-center justify-center">
          <ImageWithBasePath src="notificationsEmpty" width={251} height={301} />
          <div className="mt-[32.33px] w-[70%] text-center font-book text-lg font-normal leading-22">
            No new notifications to distract your hunger cravings.
          </div>
        </div>
      )}
    </div>
  )

  return (
    <Media greaterThan="sm">
      <Popover
        open={showNotification}
        content={content}
        onOpenChange={handleOpenChange}
        trigger="click"
      ></Popover>
      <style jsx global>{`
        .ant-popover .ant-popover-inner {
          padding: 0px;
          border-radius: 0px;
        }
      `}</style>
      <style jsx global>{`
        .ant-popover .ant-popover-arrow::before {
          width: 0px;
          height: 0px;
        }
      `}</style>
      <style jsx global>{`
        .ant-popover .ant-popover-arrow::after {
          width: 0px;
          height: 0px;
        }
      `}</style>
    </Media>
  )
}
