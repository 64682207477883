import { Drawer } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { AppContext } from './Layout'
import { Media } from '../media'
import { NoOfItemsSelector } from './NoOfItemsSelector'
import { PrimaryButton } from './PrimaryButton'
import { useDeviceSize } from '../hooks/useDeviceSize'
import { CartItemProps } from '../ts/types/cartItem.types'

export const AskCustomiseAgianModal = () => {
  const {
    theme,
    openAskCustomiseAgainModal,
    setopenAskCustomiseAgainModal,
    cartItems,
    customisationItem,
    setOpenCustomisationModal,
    setShowCustomisationScreen,
    setHeaderDetails,
    setCartItems,
    setReCustomizeItem
  } = useContext(AppContext)
  const [width] = useDeviceSize()
  const [heightVar, setHeightVar] = useState(0)
  const [cloneCartItems, setCloneCartItems] = useState([])

  useEffect(() => {
    let array: CartItemProps[] | [] = [...cartItems]
    setCloneCartItems(JSON.parse(JSON.stringify(array)))
  }, [customisationItem])

  useEffect(() => {
    let count = 0
    cartItems?.map((product: CartItemProps) => {
      if (product?.foodDetails?.title === customisationItem?.foodDetails?.title) {
        count += 1
      }
    })
    setHeightVar(count)
  }, [])

  return (
    <Media at="sm">
      <div className="shadow-contactCheffShadow">
        <Drawer
          open={openAskCustomiseAgainModal}
          closable={false}
          style={{ backgroundColor: 'white' }}
          placement="bottom"
          height={200 + heightVar * 41}
          onClose={() => {
            setCartItems(JSON.parse(JSON.stringify(cloneCartItems)))
            setopenAskCustomiseAgainModal(false)
          }}
        >
          <div className="absolute top-[0px] w-[100%] p-[24px]">
            <div
              className="font-black text-2xl font-[500] leading-[27.32px]"
              style={{ color: theme.customPrimary }}
            >
              You have customised this already
            </div>
            {cartItems?.map((product: CartItemProps, index: number) => {
              return (
                product?.foodDetails?.title === customisationItem?.foodDetails?.title && (
                  <div className="flex items-center justify-between">
                    <div className="pt-[8px] font-book text-sm font-medium leading-[16.39px]">
                      {product?.customisationString && product?.customisationString.length > 0
                        ? product?.customisationString?.substring(
                            0,
                            product?.customisationString.length - 1
                          )
                        : 'No customisation'}
                    </div>
                    <div className="pt-[16px]">
                      <NoOfItemsSelector
                        value={product?.quantity}
                        imageDetails={{ height: 15, width: 15 }}
                        style={{ height: '25px', width: '25px' }}
                        valueClassName="px-[9px] border-t-[0.74px] border-b-[0.74px] border-t-[#E5E5E5] border-b-[#E5E5E5]"
                        foodDetails={product?.foodDetails}
                        forCart={true}
                        item={product}
                        index={index}
                      />
                    </div>
                  </div>
                )
              )
            })}
            <div className="flex w-[100%] items-center justify-center pt-[28px]">
              <div
                className="w-[100%] py-[7px] text-center font-black text-sm"
                style={{
                  color: cartItems?.length > 0 ? theme.customPrimary : '#23232380'
                }}
                onClick={() => {
                  if (cartItems?.length > 0) {
                    width > 768 ? setOpenCustomisationModal(true) : setShowCustomisationScreen(true)
                    setHeaderDetails({
                      title: 'Cancel',
                      onClick: JSON.stringify(`() => {
                    setShowCustomisationScreen(false)
                    setOpenCustomisationModal(false)
                  }`)
                    })
                    setopenAskCustomiseAgainModal(false)
                    setReCustomizeItem({})
                    setCartItems(JSON.parse(JSON.stringify(cloneCartItems)))
                  }
                }}
              >
                Customise again
              </div>
            </div>
            <div className="mt-[8px] w-[100%]" onClick={() => setopenAskCustomiseAgainModal(false)}>
              <PrimaryButton title="Confirm" className="py-[9px] text-sm" />
            </div>
          </div>
        </Drawer>
      </div>
    </Media>
  )
}
