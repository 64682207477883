import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

export const CircularSpinner = ({
  className,
  size,
  color
}: {
  className?: string
  size: number
  color: string
}) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size, color: color }} spin />
  return <Spin indicator={antIcon} className={className} />
}
