import _JSXStyle from 'styled-jsx/style'
import React, { useState, useContext, useEffect } from 'react'

import { AppContext } from './Layout'
import { ChooseMealIcon } from '../svgIcons/chooseMealIcon'
import { CrossIcon } from '../svgIcons/crossIcon'
import { DownArrow } from '../svgIcons/downArrow'
import { MealDropDown } from './MealDropDown'

export const ChooseMeal = () => {
  const { mealType, theme, cartItems, setMealType, setDateChangeConformation } =
    useContext(AppContext)
  const [selectedItem, setSelectedItem] = useState<string | undefined>()
  const [showDropDown, setShowDropDown] = useState<boolean>(false)

  useEffect(() => {
    setSelectedItem(mealType?.type !== '' ? mealType?.type : 'Choose meal')
  }, [mealType])

  return (
    <div className="relative">
      <div
        className="flex h-[48px] w-[100%] items-center justify-between bg-[#FFFFFF] py-[14.5px] px-[14px]"
        onClick={() => {
          if (mealType?.type !== '' && cartItems?.length > 0) {
            setDateChangeConformation({ isShow: true, text: 'meal' })
          } else {
            setShowDropDown(!showDropDown)
          }
        }}
      >
        <ChooseMealIcon
          height="12"
          width="12"
          viewBox="0 0 12 12"
          fillColor={theme.customPrimary}
        />
        <div
          style={{ color: theme.customPrimary }}
          className="mx-[11px] mt-[2px] font-book text-md font-medium leading-[19.12px]"
        >
          {selectedItem}
        </div>
        {mealType?.type !== '' ? (
          <div
            onClick={() => {
              if (cartItems?.length > 0) {
                setDateChangeConformation({ isShow: true, text: 'meal' })
              } else {
                setMealType({
                  endTime: '',
                  startTime: '',
                  timeSlot: '',
                  type: '',
                  isPickedTimeSlot: false
                })
              }
            }}
            className="z-10"
          >
            <CrossIcon height="15" width="15" viewBox="0 0 18 18" fillColor={theme.customPrimary} />
          </div>
        ) : (
          <DownArrow height="14" width="14" viewBox="0 0 14 14" fillColor={theme.customPrimary} />
        )}
      </div>
      {showDropDown && (
        <div className="absolute top-[52px] z-20 w-[100%]">
          <MealDropDown setSelectedItem={setSelectedItem} setShowDropDown={setShowDropDown} />
          <div className="ovarallPop" onClick={() => setShowDropDown(false)}></div>
        </div>
      )}
    </div>
  )
}
