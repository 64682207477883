import classNames from 'classnames'
import { FiltersIconsHandler } from './FiltersIconsHandler'
import { SecondaryButtonProps } from '../ts/types/all.types'

export const SecondaryButton = ({
  title,
  imageUrl,
  icon,
  imageDetails = { height: 15, width: 15 },
  className = '',
  titleClassName = '',
  style
}: SecondaryButtonProps) => {
  return (
    <div className={classNames('flex items-center', className)}>
      {(icon || imageUrl) && (
        <div>
          <FiltersIconsHandler icon={icon} />
        </div>
      )}
      <div
        className={classNames(
          'ml-[8px] self-center font-book text-sm font-medium leading-[16.39px]',
          titleClassName
        )}
        style={style}
      >
        {title}
      </div>
    </div>
  )
}
