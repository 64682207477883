import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { ImageWithBasePath } from './ImageWithBasePath'

export const EmptyList = () => {
  const { mealDate } = useContext(AppContext)
  return (
    <div className="mt-[57px] flex flex-col items-center px-[24px] pb-[74px] text-center md:mt-[73px] md:px-[109px]">
      <ImageWithBasePath src="NoDishes" width={227} height={212} />
      <div className="mt-[24px] font-book text-[14px] font-medium">
        {mealDate?.isoString !== ''
          ? 'No dishes available for the selected date. Choose a different date or check back later for new menu options.'
          : 'No dishes available. Please select the date.'}
      </div>
    </div>
  )
}
