import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const RightArrow = ({ fillColor, width, height, viewBox, gradientColor }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6923 16L33 24L24.6923 32M31.8462 24H15"
        stroke="url(#paint0_linear_11_233)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11_233"
          x1="15"
          y1="16"
          x2="30.8897"
          y2="33.8759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor={gradientColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
